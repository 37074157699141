import * as React from "react";
import * as Config from "configuration";
import { Route, Routes } from "react-router-dom";
import GlobalIconsDefinition from "@valraiso-esf/esf-icons/es/global-icons-definition";
import Layout from "./components/layout";
import Page404 from "./screens/404";
import Home from "./screens/home";

import "@valraiso-esf/esf-ui/es/theme.css";

const DesignSystem = React.lazy(() => import("./screens/design-system"));
const Icons = React.lazy(() => import("./screens/icons"));
const AnnuaireEsf = React.lazy(() => import("annuaire-esf/es"));
const GestionDocumentaire = React.lazy(() => import("gestion-documentaire/es"));
const CommandeCS = React.lazy(() => import("commande-cs/es"));
const BonnesAdresses = React.lazy(() => import("bonnes-adresses/es"));
const RessourceWeb = React.lazy(() => import("ressource-web/es"));
const MonCompte = React.lazy(() => import("mon-compte/es"));
const ComptesEcole = React.lazy(() => import("comptes-ecole/es"));
const FicheSuiviEcole = React.lazy(() => import("fiche-suivi-ecole/es"));
const ReservationMoniteur = React.lazy(() => import("reservation-moniteur/es"));
const Avalanche = React.lazy(() => import("avalanche/es"));
const Coliving = React.lazy(() => import("coliving/es"));
const Covoiturage = React.lazy(() => import("covoiturage/es"));
const JourneesProfessionnelles = React.lazy(
  () => import("journees-professionnelles/es")
);
const Tracabilite = React.lazy(() => import("tracabilite/es"));
const EsfAcademy = React.lazy(() => import("esf-academy/es"));
const Accidents = React.lazy(() => import("accidents/es"));
const FaqAccidents = React.lazy(() => import("accidents/es/screens/faq"));
const PetitesAnnonces = React.lazy(() => import("petites-annonces/es"));
const Elections = React.lazy(() => import("elections/es"));
const CentreNotification = React.lazy(() => import("centre-notification/es"));
const Administration = React.lazy(() => import("administration/es"));
const Traces = React.lazy(() => import("traces/es"));

const origins = detectApiOrigins("w-esf.com", "carnet-rouge-esf.app");

const codes_actus = [
  "actu-esf",
  "actu-snmsf",
  "actu-agnmsm",
  "proshop",
  "filrouge",
];
const codes_medias = [
  "media-photo",
  "media-photocompetition",
  "media-video",
  "media-videotest",
  "media-ginette",
];
const codes_docs = ["document-esf", "document-snmsf", "document-agnmsm"];
const codes_evenements = ["agenda-esf", "agenda-snmsf"];
const codes_singlepage = ["page-esf", "page-snmsf"];

function WithLayout() {
  return (
    <Layout>
      <React.Suspense>
        <Routes>
          <Route path="" element={<Home />} />
          <Route path="design-system" element={<DesignSystem />} />
          <Route path="all-icons" element={<Icons />} />
          <Route path="annuaire-esf/*" element={<AnnuaireEsf />} />{" "}
          <Route
            path="journees-professionnelles/*"
            element={<JourneesProfessionnelles />}
          />
          <Route
            path="montagne-responsable/coliving/*"
            element={<Coliving />}
          />
          <Route
            path="montagne-responsable/covoiturage/*"
            element={<Covoiturage />}
          />
          <Route
            path="actualites/*"
            element={<GestionDocumentaire codes={codes_actus} />}
          />
          <Route
            path="documents/*"
            element={<GestionDocumentaire codes={codes_docs} />}
          />
          <Route
            path="media/*"
            element={<GestionDocumentaire codes={codes_medias} />}
          />
          <Route
            path="agenda/*"
            element={<GestionDocumentaire codes={codes_evenements} />}
          />
          <Route
            path="page/:slug"
            element={<GestionDocumentaire codes={codes_singlepage} />}
          />
          <Route path="bonnes-adresses/*" element={<BonnesAdresses />} />
          <Route path="ressource-web" element={<RessourceWeb />} />
          <Route path="comptes-ecole" element={<ComptesEcole />} />
          <Route path="fiche-suivi-ecole/*" element={<FicheSuiviEcole />} />
          <Route path="commande-carte-syndicale" element={<CommandeCS />} />
          <Route path="mon-compte/*" element={<MonCompte />} />
          <Route
            path="reservation-moniteur/*"
            element={<ReservationMoniteur />}
          />
          <Route path="avalanche" element={<Avalanche />} />
          <Route path="tracabilite/*" element={<Tracabilite />} />
          <Route path="esf-academy/*" element={<EsfAcademy />} />
          <Route path="accidents/*" element={<Accidents />} />
          <Route
            path="foire-aux-questions-accidents/*"
            element={<FaqAccidents />}
          />
          <Route path="petites-annonces/*" element={<PetitesAnnonces />} />
          <Route path="elections/*" element={<Elections />} />
          <Route path="notifications/*" element={<CentreNotification />} />
          <Route path="administration/*" element={<Administration />} />
          <Route path="traces/*" element={<Traces />} />
          <Route path="*" element={<Page404 />} />
        </Routes>
      </React.Suspense>
    </Layout>
  );
}

export default function Application() {
  return (
    <>
      {origins.map((origin) => (
        <link key={origin} rel="preconnect" href={origin} />
      ))}
      <Routes>
        <Route path="*" element={<WithLayout />} />
      </Routes>
      <GlobalIconsDefinition />
    </>
  );
}

function detectApiOrigins(...domains: string[]) {
  const origins = [];
  for (const conf of Object.values(Config.current())) {
    if (typeof conf !== "string" || !conf.startsWith("https://")) {
      continue;
    }
    let isTarget = false;
    for (const domain of domains) {
      if (conf.includes(domain)) {
        isTarget = true;
        break;
      }
    }
    if (!isTarget) {
      continue;
    }
    origins.push(new URL(conf).origin);
  }
  return Array.from(new Set(origins));
}
