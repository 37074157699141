import * as Auth from "./authorization";
import * as OAuth from "./oauth";
export class FetchError extends Error {
    status;
    constructor(status, message) {
        super(message);
        this.status = status;
    }
}
const TIMEOUT = 20000;
export async function fetchAuthenticated(input, init = {}, timeout) {
    let token = Auth.tokenFromStorage();
    if (!token) {
        Auth.deconnect();
        return;
    }
    const { token_type, access_token } = token;
    const initHeaders = init.headers ?? {};
    const headers = {
        ...initHeaders,
        Authorization: `${token_type || ""} ${access_token}`.trim(),
    };
    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), timeout || TIMEOUT);
    const response = await fetch(input, {
        ...init,
        headers,
        signal: controller.signal,
    });
    clearTimeout(id);
    if (response.ok) {
        const type = response.headers.get("Content-Type");
        if (type?.startsWith("application/json")) {
            return response.json();
        }
        const contentLength = response.headers.get("content-length");
        if (contentLength === "0") {
            return undefined;
        }
    }
    if (response.status === 401) {
        const refreshed = await OAuth.exchangeRefreshForAccessToken(token);
        if (!refreshed) {
            Auth.deconnect();
            return;
        }
        localStorage.setItem("authorization_token", JSON.stringify(refreshed));
        return fetchAuthenticated(input, init);
    }
    if (response.status === 403) {
        Auth.deconnect();
        return;
    }
    const cause = await response.text();
    throw new FetchError(response.status, "Data access error : " + cause);
}
export async function fetchAuthenticatedRaw(input, init = {}, timeout) {
    let token = Auth.tokenFromStorage();
    if (!token) {
        Auth.deconnect();
        return;
    }
    const { token_type, access_token } = token;
    const initHeaders = init.headers ?? {};
    const headers = {
        ...initHeaders,
        Authorization: `${token_type || ""} ${access_token}`.trim(),
    };
    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), timeout || TIMEOUT);
    const response = await fetch(input, {
        ...init,
        headers,
        signal: controller.signal,
    });
    clearTimeout(id);
    return response;
}
export async function fetchMaybeAuthenticated(input, init = {}) {
    let token = Auth.tokenFromStorage();
    if (token) {
        return fetchAuthenticated(input, init);
    }
    const response = await fetch(input, init);
    if (response.ok) {
        const type = response.headers.get("Content-Type");
        if (type?.startsWith("application/json")) {
            return response.json();
        }
    }
    const cause = await response.text();
    throw new FetchError(response.status, "Data access error : " + cause);
}
export async function fetchFileAuthenticated(input, init = {}) {
    let token = Auth.tokenFromStorage();
    if (!token) {
        Auth.deconnect();
        return;
    }
    const { token_type, access_token } = token;
    const initHeaders = init.headers ?? {};
    const headers = {
        ...initHeaders,
        Authorization: `${token_type || ""} ${access_token}`.trim(),
    };
    const response = await fetch(input, { ...init, headers });
    if (response.ok) {
        const blob = await response.blob();
        return window.URL.createObjectURL(blob);
    }
    if (response.status === 401) {
        const refreshed = await OAuth.exchangeRefreshForAccessToken(token);
        if (!refreshed) {
            Auth.deconnect();
            return;
        }
        localStorage.setItem("authorization_token", JSON.stringify(refreshed));
        return fetchAuthenticated(input, init);
    }
    if (response.status === 403) {
        Auth.deconnect();
        return;
    }
    const cause = await response.text();
    throw new FetchError(response.status, "Data access error : " + cause);
}
export async function fetchFileMaybeAuthenticated(input, init = {}) {
    let token = Auth.tokenFromStorage();
    if (token) {
        return fetchFileAuthenticated(input, init);
    }
    const response = await fetch(input, init);
    if (response.ok) {
        const blob = await response.blob();
        return window.URL.createObjectURL(blob);
    }
    const cause = await response.text();
    throw new FetchError(response.status, "Data access error : " + cause);
}
