import { jsx as _jsx } from "react/jsx-runtime";
import classNames from "classnames";
import css from "./loader-circle.module.css";
export default function Circle({ size = 24, className }) {
    const diagonal = Math.sqrt(2 * Math.pow(size, 2));
    const innerStyle = { width: size, height: size };
    const outerStyle = { height: diagonal };
    const classes = classNames(css.outer, className);
    return (_jsx("div", { className: classes, style: outerStyle, role: "progressbar", children: _jsx("div", { className: css.inner, style: innerStyle, children: _jsx("svg", { viewBox: "22 22 44 44", children: _jsx("circle", { className: css.circle, cx: "44", cy: "44", r: "18.4", fill: "none", strokeWidth: "3.6" }) }) }) }));
}
