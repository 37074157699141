import { jsx as _jsx } from "react/jsx-runtime";
import * as Broker from "message-broker";
import css from "./link.module.css";
import classNames from "classnames";
export default function Link({ navigate, to, external, children, className, }) {
    const classes = classNames(className, css.link);
    if (Broker.isWebApp() && external) {
        return (_jsx("a", { href: to, className: classes, target: "_blank", rel: "noreferrer", children: children }));
    }
    return (_jsx("div", { className: classes, onClick: () => link(navigate, to, external), children: children }));
}
export const link = (navigate, url, external = false) => {
    if (external) {
        sendOutboundLinkGoal(url);
        if (Broker.isWebApp()) {
            return window.open(url, "_blank");
        }
        if (Broker.isNativeApp()) {
            const urlObj = new URL(url);
            if (Broker.checkMinAppVersion("2.0.8")) {
                // TODO : À modifier quand le SSO sera en place pour toutes les applis tierces
                if (urlObj.pathname === "/EsfWebMobile/Planning.aspx" ||
                    urlObj.pathname === "/PlanningParticulierSSO/PPLogin.aspx") {
                    //   urlObj.searchParams.delete("instructor");
                    //   urlObj.searchParams.delete("school");
                    //   urlObj.searchParams.delete("token");
                    return Broker.sendToNative({
                        type: "openSessionUrl",
                        payload: url,
                    });
                }
                if (urlObj.host.includes("esfproshop.com")) {
                    return Broker.sendToNative({
                        type: "openUrlInBrowser",
                        payload: url,
                    });
                }
            }
            return Broker.sendToNative({ type: "openUrl", payload: url });
        }
    }
    else {
        navigate(url);
    }
};
const sendOutboundLinkGoal = (url) => {
    const urlObj = new URL(url);
    const path = urlObj.origin + urlObj.pathname;
    sendGoal("Outbound Link: Click", { url: path });
};
const sendGoal = (goal, props) => {
    if (window.plausible) {
        window.plausible(goal, { props });
    }
};
